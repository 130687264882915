import { useEffect,useState } from "react/";
import { Component } from "react";
import { Link } from "react-router-dom"
import './employeecard.css'
export const EmployeeList = () => {
  const [em,setEm]=useState([])
  useEffect(() => {
    async function getdata() {
      var data = await fetch("http://localhost:8080/employee");
      var res = await data.json();
      console.log(res,"res")
      setEm(res);
    }
    getdata();
  }, []);
  console.log("em",em)
function eventhand(){
 
}
  return (
    
    <div className="list_container">
      {/* On clicking this card anywhere, user goes to user details */}
      {em.map((data) =>(
    <Link to="/login"><div key={data.id } onClick={eventhand} className="employee_card">
        <img className="employee_image" src={data.image} />
        <span className="employee_name" >{data.employee_name}</span>
        <span className="employee_title">{data.title}</span>
      </div></Link>))}
    </div>
  );
};
